import "./styles.scss"

import React from "react"

const TreatmentsInfo = () => {
  return (
    <section className="treatments-info">
      <div className="container">
        <div className="d-flex flex-column-reverse flex-lg-row">
          <div className="col-lg-6 col-12">
            <p className="treatments-info__text">
              Podologia to dziedzina medycyny zajmująca się leczeniem i
              pielęgnacją stóp. W gabinecie podologicznym możemy skorzystać z
              różnorodnych zabiegów mających na celu poprawę zdrowia i kondycji
              naszych stóp.
            </p>
            <p className="treatments-info__text">
              Umów się na konsultację w celu diagnozy i dobrania odpowiedniego
              leczenia!
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <div className="treatments-info__img">
              <img
                src={require("assets/images/offer/zabiegi.jpg").default}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TreatmentsInfo
