import "./styles.scss"

import React from "react"

import TreatmentsSingle from "../TreatmentsSingle"

const TreatmentsList = ({
  offer,
  categories_list,
  currentCategory,
  setCurrentCategory,
}) => {
  const handleClick = category => {
    if (currentCategory === category) {
      setCurrentCategory(null)
    } else {
      setCurrentCategory(category)
    }
  }

  return (
    <section className="treatments-list">
      <div className="container">
        <div className="treatments-list__categories">
          <p className="treatments-list__list-desc">Pokaż wpisy w kategorii</p>
          <div className="treatments-list__categories-list">
            <button
              type="button"
              className={`treatments-list__list-item${
                currentCategory === null ? " active" : ""
              }`}
              onClick={() => setCurrentCategory(null)}
            >
              <span className="treatments-list__list-item-name">Wszystkie</span>
            </button>
            {categories_list?.map((category, index) => {
              return (
                <button
                  key={index}
                  type="button"
                  className={`treatments-list__list-item${
                    currentCategory === category.name ? " active" : ""
                  }`}
                  onClick={() => handleClick(category.name)}
                >
                  <span className="treatments-list__list-item-name">
                    {category.name}
                  </span>
                </button>
              )
            })}
          </div>
        </div>
        <div className="treatments-list__items">
          <div className="row">
            {offer.map(item => (
              <TreatmentsSingle key={item.id} item={item} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TreatmentsList
