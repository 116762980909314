import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import { TreatmentsInfo, TreatmentsList } from "page_components/treatments"

let filtered_types = []

const Treatments = ({ data }) => {
  const [currentCategory, setCurrentCategory] = useState(null)

  const breadcrumbs_data = [
    {
      name: "Zabiegi",
      href: "/zabiegi/",
    },
  ]
  if (currentCategory === null) {
    filtered_types = data?.allWpTreatment?.nodes
  } else {
    filtered_types = data?.allWpTreatment?.nodes?.filter(treatment =>
      treatment?.treatmentTypes?.nodes.some(e => e.name === currentCategory)
    )
  }

  return (
    <Layout
      seo={{
        title: "Zabiegi",
        description:
          "Pomogliśmy już ponad 25 000 pacjentów. Co roku wykonujemy kilka tysięcy zabiegów na stopach. Obecnie jesteśmy najskuteczniejszą praktyką podologiczną na Podhalu.",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Zabiegi" />
      <TreatmentsInfo />
      <TreatmentsList
        offer={filtered_types}
        categories_list={data?.allWpTreatmentType?.nodes}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpTreatment {
      nodes {
        acfTreatments {
          description
          info
          title
          thumbnail {
            altText
            sourceUrl
          }
        }
        id
        slug
        treatmentTypes {
          nodes {
            id
            name
          }
        }
      }
    }
    allWpTreatmentType {
      nodes {
        id
        name
      }
    }
  }
`

export default Treatments
